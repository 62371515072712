// import * as React from 'react'
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import moment from 'moment';

import { Layout } from '../components/Layout'
import { components } from '../slices'


const CouponsTemplate = ({ data }) => {
  if (!data) return null

  const page = data.prismicCoupons
  const seo = {
    title: page.data.seo_title,
    description: page.data.seo_description,
    keywords: page.data.seo_keywords,
  }

  const { lang, type, url } = page
  const alternateLanguages = page.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout activeDocMeta={activeDoc} seo={seo}>
      <div className="custom-header" >
        <div style={{height:"76px"}}></div>
          <div className="container py-5">
              <h3 className="text-white mb-0">{page.data.page_title}</h3>
          </div>
      </div>
      <div className="container py-5">
        <SliceZone slices={page.data.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query myCouponsQuery($lang: String) {
    prismicCoupons(lang: { eq: $lang }) {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        page_title
        page_desc {
          richText
        }
        body { 
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
					...CouponsDataBodyCoupons
        }
      }
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
    }
}
`

export default withPrismicPreview(CouponsTemplate)
